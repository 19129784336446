"use strict";
import Swiper, { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

const swiper = new Swiper(".swiper", {
  // Optional parameters
  autoplay: {
    delay: 2500,
  },
  direction: "horizontal",
  loop: true,
  modules: [Navigation, Autoplay],

  // Navigation arrows
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

//

const navbar = document.querySelector(".navbar");

const toggleMenu = function (e) {
  if (e.target.closest(".nav-button"))
    navbar.classList.toggle("nav-open");
  if (e.target.closest(".nav-link"))
    navbar.classList.remove("nav-open");
};

navbar.addEventListener("click", toggleMenu);
////TODO TODO TODO TODO hurco, mitutoyo, etc etc
